export interface FetchAllTransactionsResponse {
  transactionId: string;
  amount: number;
  date: string;
  status: string;
  paymentMethod: string;
  paymentType: string;
  balance: number;
  paymentAction: PaymentActionEnum;
}

export enum PaymentActionEnum {
  CREDIT = "CREDIT",
  DEBIT = "DEBIT"
}
