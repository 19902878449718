import React, { FC } from "react";
import styles from "../RechargePending/RechargePending.module.css";
import paymentPending from "../../../images/rechargePending.png";

const RechargePending: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.roundWhiteContainer}>
        <p className={styles.headerText}>Recharge Pending</p>
        <p className={styles.pendingText}>Your recharge is currently pending.</p>
        <img
          alt="payment pending"
          className={styles.pendingImage}
          src={paymentPending}
        />
        <div className={styles.bottomContainer}>
          <p>
            We are processing your recharge and
            <span className={styles.messageHeader}>
              <br />you will receive an update via email shortly.
            </span>
          </p>
        </div>
        <div className={styles.supportTest}>
          <p>
            If you have any questions or need further assistance,
            please contact our support team at: <br />
            <span className={styles.emailText}> support@skytelservices.com </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RechargePending;