import React, { useContext, useState, useEffect } from "react";
import Dialog, { Size } from "../../../business/Dialog/Dialog";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import { AuthContext } from "../../../../contextApi/AuthContext/authContext";
import { OTPContext } from "../../../../contextApi/OTPContext/OTPContext";
import { notyf } from "../../../../notyf";
import DotLoader from "../../../business/DotLoader/DotLoader";
import { isOTPRequired } from "../../../../requester";
import styles from "./DeleteExtension.module.css";
import classNames from "classnames";
import { deleteExtension } from "../../../../api/endpoint";

type DeleteExtensionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  extensionUsername: string;
  extensionId: string;
}

const DeleteExtension: React.FC<DeleteExtensionDialogProps> = ({
                                                                       isOpen,
                                                                       onClose,
                                                                       onSuccess,
                                                                       extensionUsername,
                                                                       extensionId
                                                                     }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess
  } = useContext(OTPContext);

  useEffect(() => {
    if (isOTPVerificationSuccess) {
      handleDeleteSuccess();
      setIsOTPVerificationSuccess(false);
    }
  }, [isOTPVerificationSuccess]);

  const handleDeleteSuccess = () => {
    setIsConfirmed(true);
    onSuccess();
    notyf.success("Extension deleted successfully");
    setIsDeleting(false);
  };

  const handleDeleteError = (error: any) => {
    setIsDeleting(false);
    if (isOTPRequired(error)) {
      setOTPRequired(true);
      setErrorResponse(error);
    } else {
      notyf.error("Failed to delete extension. Please try again later.");
    }
  };

  const handleDelete = async () => {
    if (!accessToken) return;
    setIsDeleting(true);
    try {
      await deleteExtension(accessToken, extensionId);
    } catch (error) {
      handleDeleteError(error);
    }
  };

  const handleClose = () => {
    onClose();
    setIsConfirmed(false);
  };

  if (!isOpen) return null;

  if (isConfirmed) {
    return (
      <Dialog
        size={Size.SM}
        header="Delete Extension"
        renderer={() => (
          <div className={classNames(styles.deleteExtensionContainer, styles.confirmationContainer)}>
            <span className={styles.confirmationMessage} data-testid="confirmation-text">
              Extension {extensionUsername} has been deleted successfully.
            </span>
            <div className={styles.buttonContainer}>
              <SkyButton
                onClick={handleClose}
                size={ButtonSize.SMALL}
                testId="ok-button"
                text="Ok"
              />
            </div>
          </div>
        )}
      />
    );
  }

  return (
    <Dialog
      size={Size.SM}
      header="Delete Extension"
      renderer={() => (
        <div className={styles.deleteExtensionContainer} data-testid="delete-extension-dialog">
          <div className={styles.confirmationMessage}>
            Are you sure you want to delete extension <strong>{extensionUsername}</strong>?
            <br />
            This action cannot be undone.
          </div>
          <div className={styles.buttonContainer}>
            {isDeleting ? (
              <div className={styles.loaderContainer}>
                <DotLoader />
              </div>
            ) : (
              <>
                <SkyButton
                  text="Delete"
                  onClick={handleDelete}
                  size={ButtonSize.SMALL}
                  testId="confirm-delete-button"
                  disabled={isDeleting}
                />
                <SkyButton
                  text="Cancel"
                  onClick={handleClose}
                  size={ButtonSize.SMALL}
                  testId="cancel-delete-button"
                />
              </>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default DeleteExtension;