import React, { FC, useContext, useEffect, useState } from "react";
import paymentSuccess from "../../../images/rechargeSuccess.png";
import correctLogo from "../../../images/correctsymbol.png";
import styles from "./RechargeSuccess.module.css";
import { fetchUserProfile } from "../../../api/endpoint";
import { UserDetailsResponse } from "../../../models/response/UserDetaiilsResponse";
import { notyf } from "../../../notyf";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";

export type Props = {
  transactionId: string;
  amount: number;
  date: string;
  currency: string;
  status: string;
  isMailSent: boolean;
};

const RechargeSuccess: FC<Props> = (props) => {
  const [email, setEmail] = useState<string>("");
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    fetchUserProfile(accessToken ? accessToken : "")
      .then((userDetailsResponse: UserDetailsResponse) => {
        setEmail(userDetailsResponse.email);
      })
      .catch(() => {
        notyf.error("Unable to fetch account data");
      });
  }, [accessToken]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.roundBoxContainer} data-testid="payment-success">
        <div className={styles.leftContainer}>
          <div className="correctLogo">
            <img src={correctLogo} alt="correctLogo" />
          </div>
          <div className={styles.rechargeSuccess}>
            <h2>Recharge Successful</h2>
          </div>
          <p>We are processing the recharge and you will be notified via email</p>
          <div className="image">
            <img src={paymentSuccess} alt="paymentSuccess" />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.dataRow}>
            <div className={styles.label}>Status</div>
            <div className={styles.value}>{props.status}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.label}>Transaction id</div>
            <div className={styles.value}>{props.transactionId}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.label}>Amount</div>
            <div className={styles.value}>{props.amount}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.label}>Currency</div>
            <div className={styles.value}>{props.currency}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.label}>Date</div>
            <div className={styles.value}>{props.date}</div>
          </div>
          <div className={styles.footDataContainer}>
            <div className={styles.remark}>Remark</div>
            <div className={styles.yellowTextContainer}>
              {`A confirmation email will be sent to your registered email address${email ? ` (${email})` : ''} once the recharge is processed.`}
            </div>
            <div className={styles.remarkText}>
              Your balance will be updated shortly. If you find any discrepancy, please contact our support: support@skytelservices.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RechargeSuccess;
