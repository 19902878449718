import React, { useContext, useState, useEffect, useRef } from "react";
import Dialog, { Size } from "../../../business/Dialog/Dialog";
import styles from "./ManageExtensionPassword.module.css";
import { ContentCopyOutlined, CheckCircleOutlined, InfoOutlined, LockResetOutlined } from "@mui/icons-material";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { notyf } from "../../../../notyf";
import { fetchExtensionsPassword } from "../../../../api/endpoint";
import { AuthContext } from "../../../../contextApi/AuthContext/authContext";
import { OTPContext } from "../../../../contextApi/OTPContext/OTPContext";
import DotLoader from "../../../business/DotLoader/DotLoader";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import classNames from "classnames";
import { isOTPRequired } from "../../../../requester";
import SkyTooltip from "../../../base/SkyTooltip/SkyTooltip";

enum PasswordAction {
  VIEW,
  RESET
}

type ManageExtensionPasswordDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  extensionId: string;
}

const ManageExtensionPassword: React.FC<ManageExtensionPasswordDialogProps> = ({
                                                                                       isOpen,
                                                                                       onClose,
                                                                                       extensionId,
                                                                                     }) => {
  const [isFetchingPassword, setIsFetchingPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordCopied, setIsPasswordCopied] = useState(false);
  const [passwordAction, setPasswordAction] = useState<PasswordAction>(PasswordAction.VIEW);
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const { accessToken } = useContext(AuthContext);
  const {
    setOTPRequired,
    setErrorResponse,
    isOTPVerificationSuccess,
    setIsOTPVerificationSuccess,
    otpVerificationResponse,
    setOTPVerificationResponse
  } = useContext(OTPContext);

  const pendingExtensionIdRef = useRef<string | null>(null);

  useEffect(() => {
    if (isOTPVerificationSuccess && pendingExtensionIdRef.current === extensionId) {
      if (otpVerificationResponse) {
        setPassword(otpVerificationResponse.password);
      }
      setIsOTPVerificationSuccess(false);
      pendingExtensionIdRef.current = null;
    }
  }, [isOTPVerificationSuccess, extensionId]);

  const handlePasswordFetch = async () => {
    if (!accessToken) return;

    setIsFetchingPassword(true);
    try {
      await fetchExtensionsPassword(accessToken, extensionId);
      setIsFetchingPassword(false);
    } catch (error) {
      handlePasswordFetchError(error);
    }
  };

  const validatePassword = (value: string) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()?{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,20}$/;
    const isValid = passwordRegex.test(value);
    setIsPasswordValid(isValid);
    return isValid;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewPassword(value);
    validatePassword(value);
  };

  const generateRandomPassword = () => {
    const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
    const numberChars = "0123456789";
    const specialChars = "!@#$%^&*()?{}|<>";

    let password = "";
    password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
    password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
    password += numberChars[Math.floor(Math.random() * numberChars.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;
    for (let i = password.length; i < 20; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    const shuffledPassword = password.split("").sort(() => Math.random() - 0.5).join("");
    setNewPassword(shuffledPassword);
    setIsPasswordValid(true);
  };

  const handleProceed = () => {
    if (passwordAction === PasswordAction.VIEW) {
      handlePasswordFetch();
    } else if (passwordAction === PasswordAction.RESET) {
      notyf.error("Reset password functionality will be implemented in future");
      onClose();
    }
  };

  const handlePasswordFetchError = (error: any) => {
    setIsFetchingPassword(false);
    if (isOTPRequired(error)) {
      setOTPRequired(true);
      setErrorResponse(error);
      pendingExtensionIdRef.current = extensionId;
    } else {
      const errorMessage = error?.response?.data?.message || "Failed to fetch password";
      notyf.error(errorMessage);
      onClose();
    }
  };

  const handleCloseDialog = () => {
    onClose();
    setPassword("");
    setNewPassword("");
    setIsPasswordCopied(false);
    pendingExtensionIdRef.current = null;
    setOTPVerificationResponse(null);
  };

  const handleCopyPassword = () => {
    if (password) {
      navigator.clipboard
        .writeText(password)
        .then(() => {
          setIsPasswordCopied(true);
          setTimeout(() => {
            setIsPasswordCopied(false);
          }, 1000);
        })
        .catch(() => {
          setIsPasswordCopied(false);
          notyf.error("Failed to copy password");
        });
    }
  };

  if (!isOpen) return null;

  const renderPasswordContent = () => (
    <div className={classNames(styles.viewPasswordOuterContainer)}>
      <div className={classNames(styles.passwordChoiceSelector, styles.passwordChoiceGrayFont)}>
        <div
          onClick={!isFetchingPassword ? () => setPasswordAction(PasswordAction.VIEW) : undefined}
          className={`${styles.passwordChoiceClickable} ${isFetchingPassword ? styles.disabled : ""}`}
          data-testid="view-password-option"
        >
          {passwordAction === PasswordAction.VIEW ? (
            <RadioButtonChecked className={classNames(styles.passwordChoiceSelectedIcon, styles.passwordChoiceBlue)} />
          ) : (
            <RadioButtonUnchecked />
          )}
          <span>View Password</span>
        </div>
        <div
          onClick={!isFetchingPassword ? () => setPasswordAction(PasswordAction.RESET) : undefined}
          className={`${styles.passwordChoiceClickable} ${isFetchingPassword ? styles.disabled : ""}`}
          data-testid="reset-password-option"
        >
          {passwordAction === PasswordAction.RESET ? (
            <RadioButtonChecked className={classNames(styles.passwordChoiceSelectedIcon, styles.passwordChoiceBlue)} />
          ) : (
            <RadioButtonUnchecked />
          )}
          <span>Reset Password</span>
        </div>
      </div>

      {password && passwordAction === PasswordAction.VIEW && (
        <div className={styles.passwordDisplayContainer}>
          <div className={styles.passwordIconContainer}>
            <span className={styles.passwordText} data-testid="password-display">
              {password}
            </span>
            <span
              className={styles.icon}
              onClick={handleCopyPassword}
              data-testid="copy-password"
            >
              {isPasswordCopied ? <CheckCircleOutlined /> : <ContentCopyOutlined />}
            </span>
          </div>
        </div>
      )}

      {passwordAction === PasswordAction.RESET && (
        <div className={styles.passwordResetContainer}>
          <div className={styles.inputGroup}>
            <div className={styles.inputWithIcon}>
              <input
                type="text"
                value={newPassword}
                onChange={handlePasswordChange}
                placeholder="Enter new password"
                className={classNames(
                  styles.extensionInputBox,
                  newPassword && !isPasswordValid && styles.errorInput
                )}
                data-testid="new-password-input"
              />
              <SkyTooltip
                tooltipText="Password must be 8-20 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
              >
                <span className={styles.infoIcon} data-testid="info-icon">
                  <InfoOutlined />
                </span>
              </SkyTooltip>
            </div>
            <SkyTooltip tooltipText="Generate random password">
              <span
                className={styles.generateIcon}
                onClick={generateRandomPassword}
                data-testid="generate-password"
              >
                <LockResetOutlined />
              </span>
            </SkyTooltip>
          </div>
        </div>
      )}

      {isFetchingPassword && (
        <div className={styles.loaderContainer}>
          <DotLoader />
        </div>
      )}

      {!isFetchingPassword && (
        <div className={styles.passwordChoiceDialogButtonContainer}>
          {(!password || passwordAction !== PasswordAction.VIEW) && (
            <SkyButton
              text="Proceed"
              onClick={handleProceed}
              size={ButtonSize.SMALL}
              testId="submit-password-choice-button"
              disabled={isFetchingPassword || (passwordAction === PasswordAction.RESET && !isPasswordValid)}
            />
          )}
          <SkyButton
            text="Close"
            onClick={handleCloseDialog}
            size={ButtonSize.SMALL}
            testId="close-dialog-button"
            disabled={isFetchingPassword}
          />
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      header="Extension Password Options"
      renderer={renderPasswordContent}
      size={Size.SM}
      testId="manage-extension-password-dialog"
    />
  );
};

export default ManageExtensionPassword;