import SipTrunkGraphics from "../images/Graphics-Siptrunk.png";
import ReportGraphics from "../images/Graphics-Report.png";
import RatesGraphics from "../images/Graphics-Rates.png";
import VNGraphics from "../images/Graphics-VN.png";

export enum Menu {
  DASHBOARD = "Dashboard",
  SIP_TRUNK = "Sip Trunk",
  NUMBERS = "Numbers",
  PRICING = "Pricing",
  REPORT = "Report",
  RECHARGE = "Recharge",
  CLOUD_TELEPHONY = "Cloud Telephony",
  UNKNOWN = "Unknown",
}

export enum SubMenu {
  TRANSACTIONS = "Transactions",
  OTHER_CHARGES = "Other Charges",
  RATES = "Rates",
  CDR = "CDR",
  CSR = "CSR",
  IP_TRUNK = "IP Trunk",
  USER_TRUNK = "User Trunk",
  VIRTUAL_NUMBER = "Virtual Number",
  PURCHASE_VIRTUAL_NUMBER = "Buy Number",
  CREATE_EXTENSION = "Extension",
  ALL_TRANSACTIONS = "All Transactions",
  UNKNOWN = "Unknown",
}

export type MenuItem = {
  name: string;
  description: string;
  isHover: boolean;
  route: string;
  graphics?: string;
};

export type SubMenuItem = {
  parentMenuItem: Menu;
  title: string;
  description: string;
  route: string;
  headerColor: string;
  squareColor: string;
  squareShadowColor: string;
};

export const topMenuList: MenuItem[] = [
  {
    name: "Dashboard",
    description: "Dashboard",
    isHover: false,
    route: "/dashboard",
  },
  {
    name: "Sip Trunk",
    description: "Sip Trunk",
    isHover: false,
    route: "/sipTrunk",
    graphics: SipTrunkGraphics,
  },
  {
    name: "Numbers",
    description: "Numbers",
    isHover: false,
    route: "/numbers",
    graphics: VNGraphics,
  },
  {
    name: "Cloud Telephony",
    description: "Cloud Telephony",
    isHover: false,
    route: "/cloudTelephony",
    graphics: VNGraphics,
  },
  {
    name: "Pricing",
    description: "Pricing",
    isHover: false,
    route: "/pricing",
    graphics: RatesGraphics,
  },
  {
    name: "Report",
    description: "Report",
    isHover: false,
    route: "/report",
    graphics: ReportGraphics,
  },
  {
    name: "Recharge",
    description: "Recharge",
    isHover: false,
    route: "/recharge",
  },
];

export const bottomMenuList: MenuItem[] = [
  // {
  //   name: "settings",
  //   description: "Settings",
  //   isHover: false,
  //   route: "/settings",
  // },
];

export const subMenuItemList: SubMenuItem[] = [
  {
    parentMenuItem: Menu.REPORT,
    title: SubMenu.TRANSACTIONS,
    description: "In this section customer can send credit to their account.",
    route: "/transactions/recharge",
    squareColor: "#a99afe",
    squareShadowColor: "#ccc3ff",
    headerColor: "#9180ef",
  },
  {
    parentMenuItem: Menu.REPORT,
    title: SubMenu.OTHER_CHARGES,
    description:
      "It refers to CLI charges or DID number or virtual number charges.",
    route: "/transactions/others",
    squareColor: "#a99afe",
    squareShadowColor: "#ccc3ff",
    headerColor: "#9180ef",
  },
  {
    parentMenuItem: Menu.REPORT,
    title: SubMenu.ALL_TRANSACTIONS,
    description:
      "In this section customer can see the transaction detail of the account.",
    route: "/transactions/allTransactions",
    squareColor: "#a99afe",
    squareShadowColor: "#ccc3ff",
    headerColor: "#9180ef",
  },
  {
    parentMenuItem: Menu.PRICING,
    title: SubMenu.RATES,
    description:
      "Under this section customer can find subscription pricing or per destination wise pricing and other pricing information.",
    route: "/pricing/rates",
    squareColor: "#ffc774",
    squareShadowColor: "#fde4b4",
    headerColor: "#ffc774",
  },
  {
    parentMenuItem: Menu.REPORT,
    title: SubMenu.CDR,
    description:
      "CDR or Call Details Record, under this section customer can get detail information about each and every lead like Caller ID, Called number, charged duration, call charge, Origination IP, account ID, account name, start time, end time etc.",
    route: "/report/cdr",
    squareColor: "#a99afe",
    squareShadowColor: "#ccc3ff",
    headerColor: "#9180ef",
  },
  {
    parentMenuItem: Menu.REPORT,
    title: SubMenu.CSR,
    description:
      "CSR or Call Summary Report, under this section customer can check date wise total count of dialed number, duration, call charges (UD), ASR and ACD.",
    route: "/report/csr",
    squareColor: "#a99afe",
    squareShadowColor: "#ccc3ff",
    headerColor: "#9180ef",
  },
  {
    parentMenuItem: Menu.SIP_TRUNK,
    title: SubMenu.USER_TRUNK,
    description:
      "Users Trunk , also known as SIP End Points, are the accepted standard for connecting devices such as VoIP Phones, VoIP Intercoms and VoIP Apps to a cloud based telephone switchboard (Widely referred to as a Hosted PBX)",
    route: "/sipTrunk/userTrunk",
    squareColor: "#9eddf0",
    squareShadowColor: "#c6f0ff",
    headerColor: "#5cc6e6",
  },
  {
    parentMenuItem: Menu.SIP_TRUNK,
    title: SubMenu.IP_TRUNK,
    description:
      "It basically refers to the transmission of data over the internet. And when the data is in voice form, it is referred to as VoIP. Traffic only from registered IP can be authenticated.",
    route: "/sipTrunk/ipTrunk",
    squareColor: "#5cc6e6",
    squareShadowColor: "#9fe1f7",
    headerColor: "#5cc6e6",
  },
  {
    parentMenuItem: Menu.NUMBERS,
    title: SubMenu.VIRTUAL_NUMBER,
    description:
      " A virtual number or direct inward dialing (DID), is a telephone number that is not tied to a specific phone device or line and allows the user to redirect and route calls from one number to another number, IP address, or device.",
    route: "/numbers/virtualNumbers",
    squareColor: "#E589F1",
    squareShadowColor: "#F3B1FC",
    headerColor: "#E589F1",
  },
  {
    parentMenuItem: Menu.NUMBERS,
    title: SubMenu.PURCHASE_VIRTUAL_NUMBER,
    description:
      "To be updated",
    route: "/numbers/purchaseVirtualNumber",
    squareColor: "#E589F1",
    squareShadowColor: "#F3B1FC",
    headerColor: "#E589F1",
  },
  {
    parentMenuItem: Menu.CLOUD_TELEPHONY,
    title: SubMenu.CREATE_EXTENSION,
    description:
      "In this section, users can create an extension for seamless call routing and management.",
    route: "/cloudTelephony/extension",
    squareColor: "#5cc6e6",
    squareShadowColor: "#9fe1f7",
    headerColor: "#5cc6e6",
  },
];
