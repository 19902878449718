import { UserLoginRequest } from "../models/request/UserLoginRequest";
import requester from "../requester";
import { UserLoginResponse } from "../models/response/UserLoginResponse";
import { RateDataResponse } from "../models/response/RateDataResponse";
import { AccountSummaryResponse } from "../models/response/AccountSummaryResponse";
import { FetchTransactionsResponse } from "../models/response/FetchTransactionsResponse";
import { DailyAccountSummaryReportResponse } from "../models/response/DailyAccountSummaryReportResponse";
import { TodayConsumptionResponse } from "../models/response/TodayConsumptionResponse";
import { UserDetailsResponse } from "../models/response/UserDetaiilsResponse";
import { IPTrunkListResponse } from "../models/response/IPTrunkListResponse";
import { UserTrunkListResponse } from "../models/response/UserTrunkListResponse";
import { AreaWiseCallReportResponse } from "../models/response/AreaWiseCallReportResponse";
import { VirtualNumbersResponse } from "../models/response/VirtualNumbersResponse";
import { InitiatePaymentResponse } from "../models/response/InitiatePaymentResponse";
import { CompletePaymentResponse } from "../models/response/CompletePaymentResponse";
import { OtherCharges } from "../models/response/OtherCharges";
import { CDRSearchResponse } from "../models/response/CDRSearchResponse";
import { FeatureToggle } from "../models/response/FeatureToggle";
import { UserRegistrationRequest } from "../models/request/UserRegistrationRequest";
import { UserRegistrationResponse } from "../models/response/UserRegistrationResponse";
import { DefaultGatewayGroupsResponse } from "../models/response/DefaultGatewayGroupsResponse";
import { InitiateAddIPRequest } from "../models/request/InitiateAddIPRequest";
import { CompleteAddIPRequest } from "../models/request/CompleteAddIPRequest";
import { AddIPVerificationDetailsResponse } from "../models/response/AddIPVerificationDetailsResponse";
import { MigrationRequest } from "../models/request/MigrationRequest";
import { IpStateChangeRequest } from "../models/request/IpStateChangeRequest";
import { OTPResponse } from "../models/response/OTPResponse";
import { KycRequest } from "../models/request/KycRequest";
import { ResetPasswordRequest } from "../models/request/ResetPasswordRequest";
import { City } from "../models/response/City";
import { DidGroupTypesResponse } from "../models/response/DidGroupTypesResponse";
import { DidData } from "../models/response/AvailableDidsResponse";
import { DidOrderInitiationRequest } from "../models/request/DidOrderInitiationRequest";
import {CopyIPRequest} from "../models/request/CopyIPRequest";
import {LatestKYCDataResponse} from "../models/response/LatestKYCDataResponse";
import {Country} from "../models/response/Country";
import {State} from "../models/response/State";
import {FetchInvoiceResponse} from "../models/response/FetchInvoiceResponse";
import { TermsAndConditions } from "../models/response/FetchTermsAndConditions";
import { GetExtensionResponse } from "../models/response/GetExtensionResponse";
import { CreateExtensionRequest } from "../models/request/CreateExtensionRequest";
import { RedirectVirtualNumberRequest } from "../models/request/RedirectVirtualNumberRequest";
import {FetchAllTransactionsResponse} from "../models/response/fetchAllTransactionsResponse";
export const getExtensionPasswordEndpoint = (id) => `/sipTrunk/extensions/${id}/password`;

export const featureTogglesEndPoint = "/featureToggles";
export const sendOTPEndpoint = "/user/resendOTP";
export const resetPasswordEndPoint = "/user/resetPassword";
export const loginEndPoint = "/user/login";
export const registrationEndPoint = "/user/signup";
export const migrationEndPoint = "/user/migrate";
export const kycEndPoint = "/user/kyc";
export const fetchKycEndpoint = "/user/kyc/{userId}"
export const rateEndPoint = "/call/rates?searchText={searchText}&page={page}";
export const accountSummaryEndPoint = "/user/accountSummary";
export const getTransactionsEndPoint = "/user/transactions";
export const getDailySummaryReportEndPoint =
  "/cdr/report?fromDate={fromDate}&toDate={toDate}";
export const getAreaWiseCallReportEndPoint =
  "/cdr/areaWiseReport?fromDate={fromDate}&toDate={toDate}";
export const downloadCDREndPoint =
  "/cdr/download?date={date}&from={from}&to={to}&callerNumber={callerNumber}&calledNumber={calledNumber}";
export const getTodayConsumption = "/user/getTodayConsumption";
export const getOtherChargesListEndPoint = "/user/otherCharges";
export const getUserDetailsEndPoint = "/user/profile";
export const getIPTrunkListEndPoint = "/sipTrunk/ipTrunkList";
export const getUserTrunkListEndPoint = "/sipTrunk/userTrunkList";
export const getVirtualNumberListEndPoint = "/sipTrunk/virtualNumbersList";
export const getDefaultGatewayGroupsEndPoint = "/sipTrunk/defaultGatewayGroups";
export const getIPDetailsEndPoint = "/sipTrunk/ip?requestId={requestId}";
export const addIPEndpoint = "/sipTrunk/ip?requestAction=ADD";
export const copyIPEndpoint = "/sipTrunk/ip/copy";
export const enableDisableIPEndpoint = "/sipTrunk/ip/status";
export const initiatePaymentIntentEndPoint =
  "/payment/initiate?amount={amount}";
export const processWalletPaymentEndPoint = (invoiceId) => `/payment/wallet/invoicePayment?invoiceId=${invoiceId}`;
export const completePaymentIntentEndPoint = "/payment/complete?id={intentId}";
export const getCdrSearchEndpoint =
  "/cdr/search?date={date}&page={page}&from={from}&to={to}&callerNumber={callerNumber}&calledNumber={calledNumber}";
export const fetchCountriesEndpoint = "/geo/countries";
export const fetchStatesEndpoint = "/geo/states?countryId={countryId}";
export const fetchCitiesEndpoint =
  "/geo/cities";
export const fetchDidGroupTypesEndpoint = "/did/groupTypes";
export const fetchAvailableDidsEndpoint =
  "/did/availableDids?country={countryId}&state={regionId}&city={cityId}&groupType={groupTypeId}";
export const initiateDidOrderEndpoint = "/did/order/initiate";
export const sendMattermostAlertEndpoint = (message) => `/alert?channel=mattermost&message=${message}`;
export const sendSiteAnalyticsEndpoint = "events/analytics?page={route}";
export const getInvoiceEndpoint = "/invoice";
export const fetchTermsAndConditionsEndpoint = "/user/terms/unaccepted"
export const acceptTermsEndpoint = "/user/terms/accept"
export const createExtensionEndpoint ="/sipTrunk/extensions"
export const getExtensionEndpoint ="/sipTrunk/extensions"
export const redirectVirtualNumberEndpoint =(virtualNumber) => `/sipTrunk/${virtualNumber}/redirect`;
export const deleteExtensionEndpoint =(extensionId) => `/sipTrunk/extensions/${extensionId}`;
export const getAllTransactionsEndpoint = "/user/allTransactions";

export const getHeaders = (
  accessToken?: string,
  accept?: string,
  otp?: string
) => {
  const headers: {
    "Content-Type": string;
    withCredentials: boolean;
    Authorization?: string;
    Accept?: string;
    otp?: string;
  } = {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: accept ? accept : "",
    withCredentials: true,
  };
  if (accessToken) {
    headers.Authorization = "Bearer " + accessToken;
  }
  if (otp) {
    headers.otp = otp;
  }
  return headers;
};

export const authenticateUser = (
  loginRequest: UserLoginRequest
): Promise<UserLoginResponse> => {
  const headers = getHeaders();
  return requester.post(loginEndPoint, loginRequest, {
    headers,
  });
};

export const registerUser = (
  registrationRequest: UserRegistrationRequest
): Promise<UserRegistrationResponse> => {
  const headers = getHeaders();
  return requester.post(registrationEndPoint, registrationRequest, {
    headers,
  });
};

export const migrateUser = (
  accessToken: string,
  migrationRequest: MigrationRequest
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.post(migrationEndPoint, migrationRequest, {
    headers,
  });
};

export const saveKycData = (
  accessToken: string,
  kycDataRequest: KycRequest
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.post(kycEndPoint, kycDataRequest, {
    headers,
  });
};

export const fetchLatestKycData = (
  accessToken: string,
  userId: string
): Promise<LatestKYCDataResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    fetchKycEndpoint.replace("{userId}", userId),
    {headers}
  );
}

export const fetchCallRateData = (
  accessToken: string,
  searchText: string,
  page: number
): Promise<RateDataResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    rateEndPoint
      .replace("{searchText}", searchText)
      .replace("{page}", page + ""),
    {
      headers,
    }
  );
};

export const fetchAccountSummary = (
  accessToken?: string
): Promise<AccountSummaryResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(accountSummaryEndPoint, {
    headers,
  });
};

export const fetchTransactions = (
  accessToken?: string
): Promise<FetchTransactionsResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getTransactionsEndPoint, {
    headers,
  });
};

export const fetchDailySummaryReport = (
  accessToken: string,
  fromDate: string,
  toDate: string
): Promise<DailyAccountSummaryReportResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    getDailySummaryReportEndPoint
      .replace("{fromDate}", fromDate)
      .replace("{toDate}", toDate),
    {
      headers,
    }
  );
};

export const fetchAreaWiseCallReport = (
  accessToken: string,
  fromDate: string,
  toDate: string
): Promise<AreaWiseCallReportResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    getAreaWiseCallReportEndPoint
      .replace("{fromDate}", fromDate)
      .replace("{toDate}", toDate),
    {
      headers,
    }
  );
};

export const downloadCDR = (
  accessToken: string,
  date: string,
  from?: string,
  to?: string,
  callerNumber?: string,
  calledNumber?: string
): Promise<any> => {
  const headers = getHeaders(
    accessToken,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
  return requester.get(
    downloadCDREndPoint
      .replace("{date}", date)
      .replace("{from}", from != undefined ? from + "" : "")
      .replace("{to}", to != undefined ? to + "" : "")
      .replace(
        "{callerNumber}",
        callerNumber != undefined ? callerNumber + "" : ""
      )
      .replace(
        "{calledNumber}",
        calledNumber != undefined ? calledNumber + "" : ""
      ),
    {
      responseType: "arraybuffer",
      headers,
    }
  );
};

export const fetchTodayConsumption = (
  accessToken: string
): Promise<TodayConsumptionResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(getTodayConsumption, {
    headers,
  });
};

export const fetchUserProfile = (
  accessToken: string
): Promise<UserDetailsResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(getUserDetailsEndPoint, {
    headers,
  });
};

export const fetchIpTrunkList = (
  accessToken: string
): Promise<IPTrunkListResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getIPTrunkListEndPoint, {
    headers,
  });
};

export const fetchUserTrunkList = (
  accessToken: string
): Promise<UserTrunkListResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getUserTrunkListEndPoint, {
    headers,
  });
};

export const fetchVirtualNumberList = (
  accessToken: string
): Promise<VirtualNumbersResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getVirtualNumberListEndPoint, {
    headers,
  });
};

export const fetchDefaultGatewayGroups = (
  accessToken: string
): Promise<DefaultGatewayGroupsResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getDefaultGatewayGroupsEndPoint, {
    headers,
  });
};

export const fetchIPDetails = (
  requestId: string
): Promise<AddIPVerificationDetailsResponse> => {
  const headers = getHeaders();
  return requester.get(getIPDetailsEndPoint.replace("{requestId}", requestId), {
    headers,
  });
};

export const initiateIPAdd = (
  accessToken: string,
  addIPRequest: InitiateAddIPRequest
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.post(addIPEndpoint, addIPRequest, {
    headers,
  });
};

export const completeIPAdd = (
  completeAddIPRequest: CompleteAddIPRequest
): Promise<any> => {
  const headers = getHeaders();
  return requester.patch(addIPEndpoint, completeAddIPRequest, { headers });
};

export const switchIPStatus = (
  accessToken: string,
  ipStateChangeRequest: IpStateChangeRequest
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.patch(enableDisableIPEndpoint, ipStateChangeRequest, {
    headers,
  });
};

export const copyIp = (
    accessToken: string,
    copyIpRequest: CopyIPRequest
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.post(copyIPEndpoint, copyIpRequest, {
    headers
  });
};

export const initiatePayment = (
  accessToken: string,
  amount: number
): Promise<InitiatePaymentResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    initiatePaymentIntentEndPoint.replace("{amount}", amount + ""),
    {
      headers,
    }
  );
};

export const completePayment = (
  accessToken: string,
  intentId: string
): Promise<CompletePaymentResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    completePaymentIntentEndPoint.replace("{intentId}", intentId + ""),
    {
      headers,
    }
  );
};

export const fetchOtherChargesList = (
  accessToken: string
): Promise<OtherCharges[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getOtherChargesListEndPoint, {
    headers,
  });
};

export const fetchFeatureTogglesList = (): Promise<FeatureToggle[]> => {
  const headers = getHeaders();
  return requester.get(featureTogglesEndPoint, {
    headers,
  });
};

export const resendOTP = (
    accessToken: string,
    otpReference: string
): Promise<OTPResponse> => {
  const headers = getHeaders(accessToken);
  return requester.post(sendOTPEndpoint,  otpReference, {
    headers
  });
};


export const resetPassword = (
  resetPasswordRequest: ResetPasswordRequest
): Promise<any> => {
  const headers = getHeaders();
  return requester.patch(resetPasswordEndPoint, resetPasswordRequest, {
    headers,
  });
};

export const fetchCDRSearchList = (
  accessToken: string,
  date: string,
  page: number,
  from?: string,
  to?: string,
  callerNumber?: string,
  calledNumber?: string
): Promise<CDRSearchResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    getCdrSearchEndpoint
      .replace("{date}", date)
      .replace("{page}", page + "")
      .replace("{from}", from + "")
      .replace("{to}", to + "")
      .replace("{callerNumber}", callerNumber + "")
      .replace("{calledNumber}", calledNumber + ""),
    {
      headers,
    }
  );
};

export const requestWithOTP = (
  url: string,
  method: string,
  data: string,
  otp: string,
  accessToken?: string
): Promise<any> => {
  const headers = getHeaders(accessToken, undefined, otp);
  if (method === "get")
    return requester.get(url, {
      headers,
    });
  else if (method === "post")
    return requester.post(url, data, {
      headers,
    });
  else if (method === "patch")
    return requester.patch(url, data, {
      headers,
    });
  else if (method === "delete")
    return requester.delete(url, {
      headers,
    });

  return Promise.reject("Unknown Method");
};

export const fetchCountriesForDidNumbers = (
  accessToken: string,
  prefix?: string
): Promise<Country[]> => {
  const headers = getHeaders(accessToken);
  const params = prefix ? { prefix } : {};
  return requester.get(fetchCountriesEndpoint, {
    headers,
    params
  });
};

export const fetchStatesForDidNumbers = (
  accessToken: string,
  countryId: string
): Promise<State[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    fetchStatesEndpoint.replace("{countryId}", countryId),
    {
      headers,
    }
  );
};

export const fetchCitiesForDidNumbers = (
  accessToken: string,
  countryId: string,
  stateId?: string,
  prefix?: string
): Promise<City[]> => {
  const headers = getHeaders(accessToken);
  const params = {
    countryId,
    stateId: stateId ? stateId : "",
    prefix: prefix ? prefix : "",
  };
  return requester.get(fetchCitiesEndpoint, {
    headers,
    params,
  });
};
export const fetchGroupTypesForDidNumbers = (
  accessToken: string
): Promise<DidGroupTypesResponse> => {
  const headers = getHeaders(accessToken);
  return requester.get(fetchDidGroupTypesEndpoint, {
    headers,
  });
};

export const fetchAvailableDids = (
  accessToken: string,
  countryId: string,
  stateId: string,
  cityId: string,
  groupTypeId: string
): Promise<DidData[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(
    fetchAvailableDidsEndpoint
      .replace("{countryId}", countryId)
      .replace("{regionId}", stateId)
      .replace("{cityId}", cityId)
      .replace("{groupTypeId}", groupTypeId),
    { headers }
  );
};

export const initiateDidOrder = (
  accessToken: string,
  didOrderInitiationRequest: DidOrderInitiationRequest): Promise<boolean> => {
  const headers = getHeaders(accessToken);
  return requester.post(initiateDidOrderEndpoint, didOrderInitiationRequest, { headers });
}

export const sendMattermostAlert = (
  message: string
): Promise<any> => {
  const headers = getHeaders();
  return requester.post(sendMattermostAlertEndpoint(message), message, {headers}
  );
};

export const sendSiteAnalytics = (
  accessToken: string,
  route: string): Promise<void> => {
  const headers = getHeaders(accessToken);
  return requester.post(
    sendSiteAnalyticsEndpoint.replace("{route}", route),
    null,
    {headers}
  );
}

export const getInvoice = (
  accessToken: string,
): Promise<FetchInvoiceResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getInvoiceEndpoint, {headers}
  );
};

export const processWalletPayment = (
  accessToken: string,
  invoiceId: String
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.post(processWalletPaymentEndPoint(invoiceId), {},{headers})
};

export const fetchTermsAndConditions  = (
  accessToken: string,
): Promise<TermsAndConditions[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(fetchTermsAndConditionsEndpoint, {headers}
  );
};

export const acceptTermsAndConditions = (
  accessToken: string,
  termIds: string[]
): Promise<void> => {
  const headers = getHeaders(accessToken);
  const body = { termIds };
  return requester.post(acceptTermsEndpoint, body, { headers });
};

export const createExtension = (
  accessToken: string,
  extension: CreateExtensionRequest,
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.post(createExtensionEndpoint,extension, { headers });
};

export const fetchExtensions  = (
  accessToken: string,
): Promise<GetExtensionResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getExtensionEndpoint, {headers}
  );
}

export const fetchExtensionsPassword = (
  accessToken: string,
  extensionId: string,
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.post(getExtensionPasswordEndpoint(extensionId),{},{ headers });
};

export const redirectVirtualNumber = (
  accessToken: string,
  virtualNumber: string,
  request: RedirectVirtualNumberRequest
): Promise<void> => {
  const headers = getHeaders(accessToken);
  return requester.post(redirectVirtualNumberEndpoint(virtualNumber),request, { headers });
};

export const deleteExtension = (
  accessToken: string,
  extensionId: string,
): Promise<any> => {
  const headers = getHeaders(accessToken);
  return requester.delete(deleteExtensionEndpoint(extensionId),{ headers });
};

export const fetchAllTransactions = (
  accessToken: string
): Promise<FetchAllTransactionsResponse[]> => {
  const headers = getHeaders(accessToken);
  return requester.get(getAllTransactionsEndpoint, {
    headers,
  });
};
