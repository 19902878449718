import React, {FC, useContext, useEffect, useState} from "react";
import {
  Menu,
  SubMenu,
  SubMenuItem,
  subMenuItemList,
  topMenuList,
} from "../../../domains/Menu";

import styles from "./menuTab.module.css";
import tab from "../../../icons/tabs.png";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {FeatureToggleContext} from "../../../contextApi/FeatureToggleContext/FeatureToggleContext";

export type Props = {
  selectedMenu: Menu;
  selectedSubMenu: SubMenu;
};

const MenuTab: FC<Props> = (props) => {
  const {isDIDFlowEnabled, isAllTransactionEnabled} = useContext(FeatureToggleContext);
  const [subMenuItems, setSubMenuItems] = useState<SubMenuItem[]>([]);
  const [mainMenuDescription, setMainMenuDescription] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    setSubMenuItems(
      subMenuItemList
        .filter((subMenu) =>
          (subMenu.title === SubMenu.PURCHASE_VIRTUAL_NUMBER
              ? isDIDFlowEnabled
              : true
          ) && (subMenu.title === SubMenu.ALL_TRANSACTIONS
            ? isAllTransactionEnabled
            : true)
        )
        .filter((subMenu) => subMenu.parentMenuItem == props.selectedMenu)
    );

    const selectedMenu = topMenuList.find(
      (menu) => menu.name == props.selectedMenu
    );
    setMainMenuDescription(selectedMenu ? selectedMenu.description : "");
  }, [props.selectedMenu]);

  return (
    <div className={styles.tabContainer} data-testid="tab-container">
      <div className={styles.mainMenuHeader}>
        {mainMenuDescription}
        <img src={tab} alt="tab-icon" className={styles.tabIcon}/>
      </div>

      <div className={styles.tabButtonsContainer}>
        {subMenuItems.map((subMenuItem) => {
          return (
            <div
              className={classNames(
                styles.tab,
                subMenuItem.title == props.selectedSubMenu ? styles.active : ""
              )}
              onClick={() => navigate(subMenuItem.route)}
              key={subMenuItem.title}
            >
              {subMenuItem.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MenuTab;
