import styles from "./Invoice.module.css";
import React, { useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import DotLoader from "../DotLoader/DotLoader";
import invoicePaid from "../../../icons/invoice paid.png";
import invoiceUnpaid from "../../../icons/invoice unpaid.png";
import invoicePending from "../../../icons/invoicePendingIcon.png";
import download from "../../../icons/downloadcdr.png";
import downloading from "../../../images/downloading.gif";
import {
  fetchAccountSummary,
  getInvoice,
  processWalletPayment
} from "../../../api/endpoint";
import { notyf } from "../../../notyf";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import { FetchInvoiceResponse } from "../../../models/response/FetchInvoiceResponse";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import emptyTableImage from "../../../images/emptyTable.png";
import { UserDetailsContext } from "../../../contextApi/UserDetailsContext/UserDetailsContext";
import { FeatureToggleContext } from "../../../contextApi/FeatureToggleContext/FeatureToggleContext";
import Dialog, { Size } from "../../business/Dialog/Dialog";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";
import {useNavigate} from "react-router-dom";

const Invoice = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<FetchInvoiceResponse[]>([]);
  const [search, setSearch] = useState<string>("");
  const [downloadingInvoices, setDownloadingInvoices] = useState<{ [key: string]: boolean }>({});
  const { accessToken } = useContext(AuthContext);
  const { user } = useContext(UserDetailsContext);
  const { isPayInvoiceEnabled } = useContext(FeatureToggleContext);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<FetchInvoiceResponse | null>(null);
  const navigate = useNavigate();
  const [showInsufficientBalanceDialog, setShowInsufficientBalanceDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const fetchInvoices = useCallback(async () => {
    setIsLoading(true);
    getInvoice(accessToken || "")
      .then((response) => {
        setInvoices(response);
      })
      .catch(() => {
        notyf.error("Unable to fetch invoice data");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accessToken])

  useEffect(() => {
    fetchInvoices()
  }, [accessToken, fetchInvoices])

  useEffect(() => {
      fetchAccountSummary(accessToken || "")
        .then((response) => {
          setBalance(response.balance);
        })
        .catch(() => {
          notyf.error("Unable to fetch account balance");
        });
  }, [accessToken]);

  const handleDownloadClick = (invoice: FetchInvoiceResponse) => {
    setDownloadingInvoices((prevState) => ({ ...prevState, [invoice.file]: true }));

    const binaryString = window.atob(invoice.file);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${invoice.number}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setDownloadingInvoices((prev) => ({ ...prev, [invoice.file]: false }));
  };

  const handlePayClick = (invoice: FetchInvoiceResponse) => {
    setSelectedInvoice(invoice);
    if (!balance || balance < invoice.amount) {
      setShowInsufficientBalanceDialog(true);
    } else {
      setShowPreviewDialog(true);
    }
  };

  const handleWalletPaymentConfirm = async () => {
    if (!selectedInvoice) return;
    setIsDialogLoading(true);

    try {
      await processWalletPayment(accessToken ? accessToken : "", selectedInvoice.id);
      notyf.success("Wallet payment processed successfully.");
      setShowPreviewDialog(false);
      setShowSuccessDialog(true);
      await fetchInvoices();
    } catch (error: any) {
      notyf.error(
        error?.response?.status === 402
          ? "Insufficient balance in your wallet."
          : "Unable to process wallet payment."
      );
    } finally {
      setIsDialogLoading(false);
    }
  };

  const formatDate = (date: string) => {
    const parsedDate = moment(date, "DD/MM/YYYY at HH:mm");
    return parsedDate.format("MMMM DD, YYYY");
  };

  const filteredInvoices = invoices.filter((invoice) =>
    invoice.number.toLowerCase().includes(search.toLowerCase())
  );

  interface InvoicePreviewDialogProps {
    invoice: FetchInvoiceResponse;
    onConfirm: () => void;
    onCancel: () => void;
    isProcessing: boolean;
  }

  const InvoicePreviewDialog = ({ invoice, onConfirm, onCancel, isProcessing }: InvoicePreviewDialogProps) => {
    return (
      <div className={styles.previewContainer}>
        <h3 className={styles.previewHeader} data-testid="invoice-preview-header">Invoice Payment Details</h3>

        <div className={styles.previewContent}>
          <div className={styles.previewRow}>
            <span className={styles.previewLabel} data-testid="invoice-number-label">Invoice Number:</span>
            <span className={styles.previewValuePurple} data-testid="invoice-number-value">{invoice.number}</span>
          </div>
          <div className={styles.previewRow}>
            <span className={styles.previewLabel} data-testid="amount-label">Amount to Pay:</span>
            <span className={styles.previewValuePurple} data-testid="amount-value">${invoice.amount}</span>
          </div>
          <div className={styles.previewRow}>
            <span className={styles.previewLabel} data-testid="payment-method-label">Payment Method:</span>
            <span className={styles.previewValuePurple} data-testid="payment-method-value">Wallet</span>
          </div>
          <div className={styles.previewRow}>
            <span className={styles.previewLabel} data-testid="date-label">Date:</span>
            <span className={styles.previewValuePurple} data-testid="date-value">{formatDate(invoice.createdAt)}</span>
          </div>
        </div>

        <div className={styles.paymentChoiceDialogButtonContainer}>
          {isProcessing ? (
            <DotLoader />
          ) : (
            <>
              <SkyButton
                text="Pay Now"
                size={ButtonSize.SMALL}
                onClick={onConfirm}
                testId="confirm-wallet-payment-button"
              />
              <SkyButton
                text="Cancel"
                size={ButtonSize.SMALL}
                onClick={onCancel}
                testId="cancel-wallet-payment-button"
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const InsufficientBalanceDialog = ({ invoice }: { invoice: FetchInvoiceResponse }) => {
    return (
      <div className={classNames(styles.dialogOuterContainer, styles.dialogContainer)}>
        <span className={styles.dialogText} data-testid="insufficient-balance-text">
        Your wallet balance (${balance?.toFixed(2)}) is insufficient to pay invoice {invoice.number} (${invoice.amount}).
        Please add funds to your wallet to proceed with the payment.
      </span>
        <div className={styles.dialogButtonContainer}>
          <SkyButton
            onClick={() => navigate('/recharge')}
            size={ButtonSize.SMALL}
            testId="add-funds-button"
            text="Add Funds"
          />
          <SkyButton
            onClick={() => setShowInsufficientBalanceDialog(false)}
            size={ButtonSize.SMALL}
            testId="close-button"
            text="Close"
          />
        </div>
      </div>
    );
  };

  const PaymentSuccessDialog = ({ invoice }: { invoice: FetchInvoiceResponse }) => {
    return (
      <div className={classNames(styles.dialogOuterContainer, styles.dialogContainer)}>
        <span className={styles.dialogText} data-testid="success-text">
          Payment for invoice {invoice.number} has been processed successfully. <br/>Amount paid: ${invoice.amount}
        </span>
        <div className={styles.dialogButtonContainer}>
          <SkyButton
            onClick={() => setShowSuccessDialog(false)}
            size={ButtonSize.SMALL}
            testId="ok-button"
            text="OK"
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.invoiceContainer}>
      <div className={styles.invoiceHeader}>
        Invoice Details
      </div>
      <div className={styles.invoiceTableContainer}>
        <div className={styles.invoiceTableHeaderContainer}>
          <div className={styles.searchbar}>
            <input
              value={search}
              className={styles.input}
              placeholder="Search your Invoice No."
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon className={styles.searchIcon} />
          </div>
        </div>
        <div className={styles.invoiceListContainer}>
          <div className={classNames(styles.tableHeader,
            filteredInvoices.length === 0 ? styles.borderBottom : "")}>
            <div className={styles.headerCell}>Status</div>
            <div className={styles.headerCell}>Date</div>
            <div className={styles.headerCell}>Invoice No.</div>
            <div className={styles.headerCell}>Amount</div>
            <div className={styles.headerCell}>Download</div>
            {isPayInvoiceEnabled && <div className={styles.headerCell}>Payment</div>}
          </div>
          {isLoading ? (
            <div className={styles.loaderContainer}>
              <DotLoader />
            </div>
          ) : (
            <div className={styles.bodyContainer}>
              {filteredInvoices.length > 0 ? (
                filteredInvoices.map((invoice, rowIndex) => (
                  <div
                    key={rowIndex}
                    className={classNames(styles.rowDiv,
                      invoice.status === "PAID" ? styles.paid : "",
                      invoice.status === "UNPAID" ? styles.unpaid : "",
                      invoice.status === "PENDING" ? styles.pending : ""
                    )}>
                    <div className={styles.cellDiv}>
                      {invoice.status === "PAID" && (
                        <img
                          src={invoicePaid}
                          alt="invoice paid"
                          className={styles.invoicePaidIcon}
                        />
                      )}
                      {invoice.status === "UNPAID" && (
                        <img
                          src={invoiceUnpaid}
                          alt="invoice unpaid"
                          className={styles.invoiceUnpaidIcon}
                        />
                      )}
                      {invoice.status === "PENDING" && (
                        <img
                          src={invoicePending}
                          alt="invoice pending"
                          className={styles.invoicePendingIcon}
                        />
                      )}
                    </div>
                    <div className={styles.cellDiv}>
                      {formatDate(invoice.createdAt)}
                    </div>
                    <div className={styles.cellDiv}>
                      {invoice.number}
                    </div>
                    <div className={styles.cellDiv}>
                      {invoice.amount}
                    </div>
                    <div className={styles.cellDiv}>
                      {!downloadingInvoices[invoice.file] ? (
                        <img
                          src={download}
                          alt="download"
                          onClick={() => handleDownloadClick(invoice)}
                          className={styles.clickable}
                        />
                      ) : (
                        <img
                          src={downloading}
                          alt="downloading"
                          className={styles.downloading}
                        />
                      )}
                    </div>
                    {isPayInvoiceEnabled && (
                      <div className={styles.cellDiv}>
                        <button
                          className={styles.payButton}
                          onClick={() => handlePayClick(invoice)}
                          disabled={invoice.status === "PAID" || invoice.status === "PENDING" || user?.status !== "ACTIVE"}
                        >
                          {invoice.status === "PAID" ? "Paid" : invoice.status === "PENDING" ? "Pending" : "Pay Now"}
                        </button>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <img
                  src={emptyTableImage}
                  alt="empty image"
                  className={styles.emptyImage}
                />
              )}
            </div>
          )}
          {showPreviewDialog && selectedInvoice && (
            <Dialog
              testId="invoice-preview-dialog"
              size={Size.SM}
              header="Invoice Payment Details"
              renderer={() => (
                <InvoicePreviewDialog
                  invoice={selectedInvoice}
                  onConfirm={handleWalletPaymentConfirm}
                  onCancel={() => setShowPreviewDialog(false)}
                  isProcessing={isDialogLoading}
                />
              )}
            />
          )}
          {showInsufficientBalanceDialog && selectedInvoice && (
            <Dialog
              testId="insufficient-balance-dialog"
              size={Size.SM}
              header="Insufficient Balance"
              renderer={() => (
                <InsufficientBalanceDialog invoice={selectedInvoice} />
              )}
            />
          )}
          {showSuccessDialog && selectedInvoice && (
            <Dialog
              testId="payment-success-dialog"
              size={Size.SM}
              header="Payment Successful"
              renderer={() => (
                <PaymentSuccessDialog invoice={selectedInvoice} />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoice;