import React from "react";
import styles from "./VirtualNumberRegistrationDetails.module.css";
import classNames from "classnames";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import Dialog, { Size } from "../../../business/Dialog/Dialog";
import { Registration } from "../../../../models/response/GetExtensionResponse";

type VirtualNumberRegistrationDetailsProps = {
  isOpen: boolean;
  onClose: () => void;
  registrations: Registration[];
};

const VirtualNumberRegistrationDetails: React.FC<VirtualNumberRegistrationDetailsProps> = ({
                                                                                             isOpen,
                                                                                             onClose,
                                                                                             registrations
                                                                                           }) => {
  if (!isOpen) return null;

  const renderRegistrationsContent = () => (
    <div className={classNames(styles.dialogContainer)}>
      <div className={styles.tableContainer}>
        {registrations.length === 0 ? (
          <p className={styles.noRegistrationMessage}>
            No registration details found for this extension.
          </p>
        ) : (
          <div className={styles.registrationsGrid}>
            <div className={styles.registrationsHeader}>
              <div className={styles.headerSerialNo}>Sr. No</div>
              <div className={styles.headerClientAgent}>Client Agent</div>
              <div className={styles.headerRegistrationIp}>IP Address</div>
            </div>
            <div className={styles.registrationsBody}>
              {registrations.map((registration, index) => (
                <div key={registration.id} className={styles.registrationRow}>
                  <div className={styles.serialNumberCol}>{index + 1}</div>
                  <div className={styles.clientAgentCol}>{registration.clientAgent}</div>
                  <div className={styles.registrationIpCol}>{registration.registrationIpAddress}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <SkyButton
          onClick={onClose}
          size={ButtonSize.SMALL}
          testId="registrations-close-button"
          text="Close"
        />
      </div>
    </div>
  );

  return (
    <Dialog
      header="Registration Details"
      renderer={renderRegistrationsContent}
      size={Size.M}
      testId="virtual-number-registrations-dialog"
    />
  );
};

export default VirtualNumberRegistrationDetails;