import React from "react";
import { NumberRedirection } from "../../../../models/response/GetExtensionResponse";
import styles from "./VirtualNumberRedirectionDetails.module.css";
import classNames from "classnames";
import SkyButton, { ButtonSize } from "../../../base/SkyButton/SkyButton";
import Dialog, { Size } from "../../../business/Dialog/Dialog";

type VirtualNumberRedirectionsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  redirections: NumberRedirection[];
};

const VirtualNumberRedirectionDetails: React.FC<VirtualNumberRedirectionsDialogProps> = ({
                                                                                           isOpen,
                                                                                           onClose,
                                                                                           redirections
                                                                                         }) => {
  if (!isOpen) return null;

  const renderRedirectionsContent = () => (
    <div className={classNames(styles.dialogContainer)}>
      <div className={styles.tableContainer}>
        {redirections.length === 0 ? (
          <p className={styles.noRedirectionMessage}>
            No virtual numbers configured for this extension.
          </p>
        ) : (
          <div className={styles.redirectionsGrid}>
            <div className={styles.redirectionsHeader}>
              <div className={styles.headerSerialNo}>Sr. No</div>
              <div className={styles.headerRedirectedNumber}>Redirected Number</div>
            </div>
            <div className={styles.redirectionsBody}>
              {redirections.map((redirection, index) => (
                <div key={redirection.id} className={styles.redirectionRow}>
                  <div className={styles.serialNumberCol}>{index + 1}</div>
                  <div className={styles.redirectedNumberCol}>{redirection.virtualNumber}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <SkyButton
          onClick={onClose}
          size={ButtonSize.SMALL}
          testId="redirections-close-button"
          text="Close"
        />
      </div>
    </div>
  );

  return (
    <Dialog
      header="Redirected Numbers"
      renderer={renderRedirectionsContent}
      size={Size.M}
      testId="virtual-number-redirections-dialog"
    />
  );
};

export default VirtualNumberRedirectionDetails;