import React, {useContext, useEffect, useState} from 'react'
import styles from "./TransactionsLog.module.css";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import {AuthContext} from "../../../contextApi/AuthContext/authContext";
import {fetchAllTransactions} from "../../../api/endpoint";
import {notyf} from "../../../notyf";
import {
  FetchAllTransactionsResponse,
  PaymentActionEnum
} from "../../../models/response/fetchAllTransactionsResponse";
import SkyTooltip from "../../base/SkyTooltip/SkyTooltip";
import classNames from "classnames";
import {ArrowDownRight, ArrowUpRight, InfoIcon, Copy} from "lucide-react";
import Dialog, {Size} from "../../business/Dialog/Dialog";
import SkyButton, {ButtonSize} from "../../base/SkyButton/SkyButton";

const TransactionsLog = () => {
  const [allTransactionData, setAllTransactionData] = useState<FetchAllTransactionsResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<
    FetchAllTransactionsResponse | null
  >(null);
  const {accessToken} = useContext(AuthContext);
  const columns: string[] = [
    "Sr. No.",
    "Amount",
    "Balance",
    "Status",
    "Credit / Debit",
    "Date",
  ];

  useEffect(() => {
    setIsLoading(true)
    fetchAllTransactions(accessToken ? accessToken : "")
      .then((res) => {
        setAllTransactionData(res)
      })
      .catch((error) => {
        notyf.error("Unable to fetch All Transactions data");
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, [accessToken]);

  const getStatusBadge = (status: string) => {
    if (status === "SUCCEEDED") return styles.success;
    else if (status === "PENDING") return styles.pending;
    else return styles.failed;
  }

  const getStatusText = (status: string) => {
    if (status === "SUCCEEDED") return "Success";
    else if (status === "PENDING") return "Pending";
    else return "Failed";
  }

  const handleCopyToClipboard = () => {
    if (selectedTransaction?.transactionId) {
      navigator.clipboard
        .writeText(selectedTransaction.transactionId)
        .then(() => {
          notyf.success("Transaction ID copied to clipboard!");
        })
        .catch(() => {
          notyf.error("Failed to copy Transaction ID.");
        });
    }
  }

  const TransactionInfo = () => {
    return (
      <div className={styles.transactionInfoContainer}>
        <table className={styles.details}>
          <tbody>
          <tr>
            <td> Transaction Id :</td>
            <td className={classNames(styles.transactionBodyDetail, styles.position)}>
              {selectedTransaction?.transactionId}
              <span className={styles.copyIcon}>
                <Copy
                  size={12}
                  onClick={handleCopyToClipboard}
                  data-testid={"copy-icon"}
                />
              </span>
            </td>
          </tr>
          <tr>
            <td> Payment Method :</td>
            <td className={styles.transactionBodyDetail}>
              {selectedTransaction?.paymentMethod.replace(/_/g, " ")}
            </td>
          </tr>
          <tr>
            <td> Payment Type :</td>
            <td className={styles.transactionBodyDetail}>
              {selectedTransaction?.paymentType === "DEDUCT_BALANCE"
                ? "BALANCE DEDUCTED"
                : selectedTransaction?.paymentType.replace(/_/g, " ")}
            </td>
          </tr>
          </tbody>
        </table>
        <div className={styles.buttonContainer}>
          <SkyButton
            size={ButtonSize.SMALL}
            text={"Close"}
            testId={"close-button"}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container} data-testid="all-transactions-sheet">
      {isOpen && (
        <Dialog
          size={Size.M}
          header="Transaction Details"
          renderer={TransactionInfo}
        />
      )}
      <div className={styles.tableContainer}>
        <SkyGrid
          columns={columns}
          isLoading={isLoading}
          renderer={allTransactionData.map((obj, index) => [
            () => <div>
              {(index + 1).toString().padStart(4, "0")}
            </div>,
            () => <div>
              ${parseFloat(String(obj.amount)).toFixed(2)}
            </div>,
            () => <div>
              {obj.balance
                ? `$${parseFloat(String(obj.balance)).toFixed(2)}`
                : "N/A"
              }
            </div>,
            () => <div className={classNames(
              styles.statusBadge,
              getStatusBadge(obj.status))}
            >
              {getStatusText(obj.status)}
            </div>,
            () => <div>
              {obj.paymentAction === PaymentActionEnum.CREDIT ? (
                <div className={classNames(styles.transactionType, styles.creditBadge)}>
                  <ArrowUpRight size={16}/>
                  Credit
                </div>
              ) : (
                <div className={classNames(styles.transactionType, styles.debitBadge)}>
                  <ArrowDownRight size={16}/>
                  Debit
                </div>
              )}
            </div>,
            () => <div className={styles.dateContainer}>
              {obj.date}
              <div className={styles.infoIcon}>
                <SkyTooltip
                  tooltipText={"More Details"}
                >
                  <InfoIcon
                    size={16}
                    onClick={() => {
                      setIsOpen(true)
                      setSelectedTransaction(obj);
                    }}
                    data-testid={"info-icon"}
                  />
                </SkyTooltip>
              </div>
            </div>,
          ])}
        />
      </div>
    </div>
  )
}
export default TransactionsLog
