import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./SubMenuScreen.module.css";
import SubMenuCardsContainer from "../../components/business/SubMenuCardsContainer/subMenuCardsContainer";
import SubMenuInfoContainer from "../../components/business/SubMenuInfoContainer/subMenuInfoContainer";
import { FeatureToggleContext } from "../../contextApi/FeatureToggleContext/FeatureToggleContext";
import {
  Menu,
  SubMenu,
  SubMenuItem,
  subMenuItemList,
  topMenuList,
} from "../../domains/Menu";

export type Props = {
  menu: Menu;
};

const SubMenuScreen: FC<Props> = (props) => {
  const { isDIDFlowEnabled ,isAllTransactionEnabled} = useContext(FeatureToggleContext);
  const [subMenuItems, setSubMenuItems] = useState<SubMenuItem[]>([]);
  const [menuDescription, setMenuDescription] = useState<string>("");
  const [graphics, setGraphics] = useState<string>("");

  useEffect(() => {
    setSubMenuItems(
      subMenuItemList
        .filter((subMenu) =>
          (subMenu.title === SubMenu.PURCHASE_VIRTUAL_NUMBER || subMenu.title === SubMenu.CREATE_EXTENSION
            ? isDIDFlowEnabled
            : true
          ) && (subMenu.title === SubMenu.ALL_TRANSACTIONS
            ? isAllTransactionEnabled
            : true)
        )
        .filter((subMenu) => subMenu.parentMenuItem == props.menu)
    );

    const selectedMenu = topMenuList.find((menu) => menu.name == props.menu);
    setMenuDescription(selectedMenu ? selectedMenu.description : "");
    setGraphics(
      selectedMenu ? (selectedMenu.graphics ? selectedMenu.graphics : "") : ""
    );
  }, [props.menu, isDIDFlowEnabled]);

  return (
    <div className={styles.subMenuContainer} data-testid="submenu-container">
      <div className={styles.subMenuHeader}>{menuDescription}</div>
      <SubMenuCardsContainer subMenuItems={subMenuItems} />
      <SubMenuInfoContainer subMenuItems={subMenuItems} graphics={graphics} />
    </div>
  );
};

export default SubMenuScreen;
