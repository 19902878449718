import React, { FC, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./OTPForm.module.css";
import OtpInput, { InputProps } from "react-otp-input";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";
import DotLoader from "../DotLoader/DotLoader";
import Dialog from "../Dialog/Dialog";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import { requestWithOTP, resendOTP } from "../../../api/endpoint";
import { isWrongOTP } from "../../../requester";
import { notyf } from "../../../notyf";
import { OTPContext } from "../../../contextApi/OTPContext/OTPContext";
import { FeatureToggleContext } from "../../../contextApi/FeatureToggleContext/FeatureToggleContext";
import { ToastContainer } from "react-toastify";

const OTPForm: FC = () => {
  const [otp, setOTP] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { accessToken } = useContext(AuthContext);
  const { isResendOTPEnabled } = useContext(FeatureToggleContext);
  const [resendTimer, setResendTimer] = useState<number>(59);
  const [resendLinkVisible, setResendLinkVisible] = useState<boolean>(false);
  const {
    otpRequired,
    errorResponse,
    setOTPRequired,
    setIsOTPVerificationSuccess,
    setOTPVerificationResponse,
  } = useContext(OTPContext);


  useEffect(() => {
    setResendTimer(59);
    setResendLinkVisible(false);
  }, [otpRequired]);

  useEffect(() => {
    let intervalId;
    if (otpRequired && isResendOTPEnabled) {
      (intervalId = setInterval(() => {
        resendTimer > 1
            ? setResendTimer((prevTimer) => prevTimer - 1)
            : (setResendLinkVisible(true), clearInterval(intervalId));
      }, 1000));
    }
    return () => clearInterval(intervalId);
  }, [otpRequired, resendTimer, isResendOTPEnabled]);


  const handleResendOTP = () => {
    const otpReferenceToken = errorResponse?.response?.headers["otpresendref"];
    setIsLoading(true);
    setError("");
    setOTP("");

    resendOTP(accessToken ? accessToken : "", otpReferenceToken )
        .then(() => {
          notyf.success("OTP successfully sent on registered mail id");
          setIsLoading(false);
          setResendTimer(59);
          setResendLinkVisible(false);
        })
        .catch(() => {
          notyf.error("Error while resending OTP");
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  const invokeRequestWithOTP = () => {
    const url = errorResponse?.config?.url;
    const method = errorResponse?.config?.method;
    const data = errorResponse?.config?.data;
    setError("");

    if (otp?.length < 6) {
      setError("Please enter correct OTP");
      return;
    }

    const methodsWithoutData = ['get', 'delete'];
    if (url && method && (data || methodsWithoutData.includes(method.toLowerCase())) && otp) {
      setIsLoading(true);
      requestWithOTP(
        url,
        method,
        data,
        otp,
        accessToken ? accessToken : undefined
      )
        ?.then((response) => {
          setOTPVerificationResponse(response);
          setOTPRequired(false);
          setOTP("");
          setIsOTPVerificationSuccess(true);
        })
        .catch((error) => {
          if (isWrongOTP(error)) {
            setError("Incorrect OTP");
          } else notyf.error("Unable to verify OTP. Please try again.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
      <>
        {otpRequired && (
            <Dialog
                testId={"otp-dialog"}
                header={"Enter OTP"}
                bringToFront={true}
                renderer={() => (
                    <div className={classNames(styles.otpContainer)}>
                      <p className={styles.infoText}>
                        An OTP has been sent to your email ID. Please enter the OTP to
                        continue.
                      </p>
                      <OtpInput
                          value={otp}
                          onChange={setOTP}
                          inputStyle={classNames(
                              styles.otpInputBox,
                              error.length > 0 ? styles.otpInputBoxOnError : ""
                          )}
                          containerStyle={styles.otpInputsContainer}
                          numInputs={6}
                          renderSeparator={<div className={styles.separator} />}
                          renderInput={(props: InputProps) => (
                              <input role={"input"} {...props} />
                          )}
                      />
                      <p className={styles.errorText}>{error}</p>
                      <p className={styles.timerText}>
                        {isResendOTPEnabled && !isLoading && (
                            resendLinkVisible ? (
                                <span onClick={handleResendOTP} className={styles.resendText}>
                    Resend OTP </span>
                            ) : (
                                `Not Received? Retry in ${resendTimer} secs`
                            )
                        )}
                      </p>
                      {!isLoading && (
                          <>
                            <SkyButton
                                onClick={() => invokeRequestWithOTP()}
                                size={ButtonSize.SMALL}
                                text={"Submit"}
                                disabled={false}
                            />
                            &nbsp; &nbsp;
                            <SkyButton
                                onClick={() => {
                                  setOTP("");
                                  setOTPRequired(false);
                                  setError("");
                                }}
                                size={ButtonSize.SMALL}
                                text={"Close"}
                                disabled={false}
                            />
                          </>
                      )}
                      {isLoading && <DotLoader />}
                      <ToastContainer />
                    </div>
                )}
            />
        )}
      </>
  );
};

export default OTPForm;
